import React from "react";
import { Replace } from "../utils/Replace";
import { Lang, useLang } from "./useLang";

export interface LocaleObject<T = string> {
  pl?: T;
  en?: T;
  de?: T;
}

export function useLocale() {
  const lang = useLang();

  return {
    T: function (ob: LocaleObject) {
      return Replace(valueFromLocaleObject(ob, lang), "/n", <br />);
    },
    TS: function (ob: LocaleObject) {
      return valueFromLocaleObject(ob, lang);
    },
  };
}

function valueFromLocaleObject(
  localeObject: LocaleObject<string>,
  lang: Lang
): string {
  if (!localeObject) return "";

  const value = localeObject[lang];
  if (value !== undefined) return value;

  const pl = localeObject["pl"];
  if (pl !== undefined) return pl;

  const en = localeObject["en"];
  if (en !== undefined) return en;

  const de = localeObject["de"];
  if (de !== undefined) return de;

  return "";
}
