import React, { useContext, useEffect, useState } from "react";
import startImage from "../../assets/mac2.jpg";
import mouse from "../../assets/mouse.svg";

import TO from "../../Translate.json";
import { useLocale } from "../../hooks/useLocale";
import { ContextApp } from "../../context";

const Start: React.FC = () => {
    const [ViewerUrl, changeViewerUrl] = useState<string | undefined>(
        undefined
    );
    const { T } = useLocale();
    const [index, setIndex] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);
    const context = useContext(ContextApp);

    const item = TO.start.items[index] ||
        TO.start.items[0] || {
        primary: { pl: "", en: "" },
        secondary: { pl: "", en: "" },
    };

    useEffect(() => {
        setShowOverlay(true);
        setTimeout(() => setShowOverlay(false), 100);
    }, [index]);

    return (
        <>
            <div className="start" id="start">
                <div className="start__wrapper">
                    <div className="start__header">
                        <div className="start__header__space"></div>
                        <div className="start__header-wrapper">
                            <div className="start__header__primary">
                                {T(item.primary)}
                            </div>
                            <div className="start__header__secondary">
                                {T(item.secondary)}
                            </div>
                            <div
                                className={`start__overlay ${showOverlay ? "show" : ""
                                    }`}
                            ></div>
                        </div>
                        <div className="start__header__scroll">
                            <div
                                className="start__header__scroll_icon"
                                onClick={() => {
                                    moveIndex(-1);
                                }}
                            >
                                keyboard_arrow_left
                            </div>
                            <div
                                className="start__header__scroll_icon"
                                onClick={() => moveIndex(1)}
                            >
                                keyboard_arrow_right
                            </div>
                        </div>
                    </div>
                    <div className="start__image">
                        <div className="start__monitor">
                            <div
                                className="start__monitor-clicker"
                                onClick={() =>
                                    openViewer(APP_CONFIG.Start.Viewer)
                                }
                            >
                                <button
                                    className="button"
                                    onClick={() =>
                                        openViewer(APP_CONFIG.Start.Viewer)
                                    }
                                >
                                    Otwórz spacer 3D
                                </button>
                            </div>
                            <iframe
                                className="start__monitor-iframe"
                                src={APP_CONFIG.Start.Viewer}
                                frameBorder="none"
                            ></iframe>
                        </div>
                    </div>
                    <div className="start__mouse">
                        <img src={mouse} className="start__mouse-img" />
                    </div>
                </div>
            </div>
            {ViewerUrl ? (
                <div className="styles-presentation">
                    <div className="styles-presentation-bg"> <div className="styles-presentation-button" onClick={() => closeViewer()}>
                        <button
                            className="button iframe"

                        >
                            close
                            </button>
                    </div>
                        <iframe
                            className="styles-presentation-iframe"
                            src={ViewerUrl}
                            frameBorder="none"
                        />{" "}

                    </div>
                </div>
            ) : null}
        </>
    );

    function moveIndex(direction: 1 | -1) {
        let newIndex = index + direction;
        newIndex =
            newIndex < 0
                ? TO.start.items.length - 1
                : newIndex >= TO.start.items.length
                    ? 0
                    : newIndex;
        setIndex(newIndex);
    }

    function openViewer(url: string | undefined) {
        changeViewerUrl(url);
        context.disableScroll.setIsDisabledScroll(true);
    }

    function closeViewer() {
        changeViewerUrl(undefined);
        context.disableScroll.setIsDisabledScroll(false);
    }
};

export default Start;
