import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import mainlogo from "../assets/3d_estate_logo.png";
import { useLocale } from "../hooks/useLocale";
import { useLang, locationStripLang, locationByLang } from "../hooks/useLang";
import { ROUTES } from "../Router";
import TO from "../Translate.json";

interface Props {
  transparent?: boolean;
}

function useIsOnTop() {
  const [isOnTop, setIsOnTop] = useState(true);

  useEffect(() => {
    const scrollHandle = () => {
      if (!isOnTop && window.scrollY < 20) {
        setIsOnTop(true);
      } else if (isOnTop && window.scrollY >= 20) {
        setIsOnTop(false);
      }
    };

    window.addEventListener("scroll", scrollHandle);

    return () => window.removeEventListener("scroll", scrollHandle);
  }, [isOnTop]);

  return isOnTop;
}

const Nav: React.FC<Props> = ({ transparent }) => {
  const [isOpen, isOpenChange] = useState(false);
  const isOnTop = useIsOnTop();
  const location = useLocation();

  const lang = useLang();

  const pathNoLang = locationStripLang(location.pathname + location.hash);

  const { T } = useLocale();

  function changeState() {
    isOpenChange((p) => !p);
  }

  function closeNav() {
    isOpenChange(false);
  }

  function compareFullLocation(route: string) {
    return route === location.pathname + location.hash;
  }

  function saveLang(lang: string) {
    window.localStorage.setItem("saved-lang", lang);
  }

  return (
    //${location.pathname === locationByLang(ROUTES.Pricing, lang) && isOnTop ? "see-through" : ""}
    <div
      className={`_nav
             ${isOpen ? "is-open" : ""} ${
        location.pathname === locationByLang(ROUTES.SmartModel, lang)
          ? "makietanav"
          : ""
      }`}
    >
      <div className="_nav__center">
        <NavLink to={locationByLang(ROUTES.Start, lang)} className="_nav__logo">
          <img src={mainlogo} className="_nav__logo-img" alt="3D Estate" />
        </NavLink>
        <div className="_nav__right">
          <div className="_nav__right-button" onClick={() => changeState()}>
            {isOpen ? "close" : "notes"}
          </div>
        </div>
        <div className="_nav__links">
          <div className="_nav__links-left">
            {lang === "de" ? null : (
              <div className="_nav__link-wrapper">
                <div className="_nav__link" onClick={() => closeNav()}>
                  <NavLink
                    to={locationByLang(ROUTES.HowItWorks, lang)}
                    className={`_nav__link-button ${
                      compareFullLocation(
                        locationByLang(ROUTES.HowItWorks, lang)
                      )
                        ? "is-deep-active"
                        : ""
                    }`}
                  >
                    {T(TO.nav.link[1])}
                  </NavLink>
                </div>
                <div className="_nav__link-dot"></div>
              </div>
            )}
            <div className="_nav__link-wrapper">
              <div className="_nav__link" onClick={() => closeNav()}>
                <NavLink
                  to={locationByLang(ROUTES.Styles, lang)}
                  className={`_nav__link-button ${
                    compareFullLocation(locationByLang(ROUTES.Styles, lang))
                      ? "is-deep-active"
                      : ""
                  }`}
                >
                  {T(TO.nav.link[2])}
                </NavLink>
              </div>
              <div className="_nav__link-dot"></div>
            </div>
            {/* <div className="_nav__link-wrapper">
                            <div className="_nav__link" onClick={() => closeNav()}>
                                <NavLink
                                    to={ROUTER_HOME.WhoTrusted}
                                    className="_nav__link-button"
                                >
                                    {T(TO.nav.link[3])}
                                </NavLink>
                            </div>
                            <div className="_nav__link-dot"></div>
                        </div> */}
            <div className="_nav__link-wrapper">
              <div className="_nav__link" onClick={() => closeNav()}>
                <NavLink
                  to={locationByLang(ROUTES.Contact, lang)}
                  className={`_nav__link-button ${
                    compareFullLocation(locationByLang(ROUTES.Contact, lang))
                      ? "is-deep-active"
                      : ""
                  }`}
                >
                  {T(TO.nav.link[4])}
                </NavLink>
              </div>
              {lang === "de" ? null : <div className="_nav__link-dot"></div>}
            </div>
            {/* <div className="_nav__link-wrapper">
                            <div className="_nav__link" onClick={() => closeNav()}>
                                <NavLink
                                    to={locationByLang(ROUTES.Pricing, lang)}
                                    className={`_nav__link-button ${
                                        locationByLang(ROUTES.Pricing, lang) === location.pathname
                                            ? "is-deep-active"
                                            : ""
                                    }`}
                                >
                                    {T(TO.nav.link[5])}
                                </NavLink>
                            </div>{" "}
                            <div className="_nav__link-dot"></div>
                        </div> */}
            {lang === "de" ? null : (
              <div className="_nav__link-wrapper">
                <div className="_nav__link" onClick={() => closeNav()}>
                  <NavLink
                    to={locationByLang(ROUTES.SmartModel, lang)}
                    className="_nav__link-button border"
                  >
                    {T(TO.nav.link[7])}
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          <div className="_nav__links-right">
            <div className="_nav__links-right__empty"></div>
            <div className="_nav__link-language-switch">
              <Link
                to={"/pl" + pathNoLang}
                className={`_nav__link lang ${lang === "pl" ? "selected" : ""}`}
                onClick={() => {
                  saveLang("pl");
                }}
              >
                PL
              </Link>

              <Link
                to={"/en" + pathNoLang}
                className={`_nav__link lang ${lang === "en" ? "selected" : ""}`}
                onClick={() => {
                  saveLang("en");
                }}
              >
                EN
              </Link>
              <Link
                to={"/de" + pathNoLang}
                className={`_nav__link lang-de ${
                  lang === "de" ? "selected" : ""
                }`}
                onClick={() => {
                  saveLang("de");
                }}
              >
                DE
              </Link>
            </div>
            <div className="_nav__link-socialmedia">
              <a
                href="https://www.facebook.com/3D-Estate-101689081475413"
                className="_nav__link-socialmedia-icon"
              >
                f
              </a>
              <a
                href="https://www.linkedin.com/company/3d-estate-3d/"
                className="_nav__link-socialmedia-icon"
              >
                in
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
