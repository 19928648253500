import React, { useEffect } from "react";
import ReactHelmet from "react-helmet";

import Start from "./Start";
import About from "./About";
import Contact from "./Contact";
import WhoTrusted from "./WhoTrusted";
import Styles from "./Styles";
import { useLocation } from "react-router-dom";
import { goToStartSection } from "../../utils/GoToStartSection";
import { locationByLang, useLang } from "../../hooks/useLang";
import { ROUTES } from "../../Router";
import { useLocale } from "../../hooks/useLocale";

const MainPage: React.FC = () => {
    const { TS } = useLocale();
    const location = useLocation();
    const lang = useLang();

    useEffect(() => {
        const fullLocation = location.pathname + location.hash;

        switch (fullLocation) {
            case locationByLang(ROUTES.HowItWorks, lang):
                goToStartSection("jaktodziala");
                break;

            case locationByLang(ROUTES.Styles, lang):
                goToStartSection("naszestyle");
                break;

            case locationByLang(ROUTES.Contact, lang):
                goToStartSection("kontakt");
                break;

            default:
                window.scrollTo({ top: 0, behavior: "smooth" });
                break;
        }
    }, [location]);

    return (
        <>
            <Start />
            <About />
            <Styles />
            <WhoTrusted />
            <Contact />
        </>
    );
};

export default MainPage;
