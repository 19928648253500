import React, { useEffect } from "react";
import { useLocale } from "../../hooks/useLocale";
import TO from "../../Translate.json";
import ReactHelmet from "react-helmet";

const PrivacyPolicy: React.FC = () => {
    const { T, TS } = useLocale();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="regulations">
            <ReactHelmet title={`3D Estate | ${TS({ pl: "Polityka Prywatności", en: "Privacy Policy" })}`}>
                <meta name="robots" content="noindex" />
            </ReactHelmet>
            <div className="regulations__center">
                <div className="regulations__text">{T(TO.privacypolicy.text1)}</div>
                <ul className="regulations__bullets">
                    <li>{T(TO.privacypolicy.bullet1)}</li>
                    <li>{T(TO.privacypolicy.bullet2)}</li>
                    <li>{T(TO.privacypolicy.bullet3)}</li>
                </ul>
                <div className="regulations__text">{T(TO.privacypolicy.text2)}</div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
