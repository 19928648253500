import React, { useContext, useState } from "react";
import amsterdam from "../../assets/amsterdam.jpg";
import paris from "../../assets/paris_new.png";
import oslo from "../../assets/oslo_new.png";
import berlin from "../../assets/berlin_new.png";
import london from "../../assets/london_new.png";
import { useLocale } from "../../hooks/useLocale";
import TO from "../../Translate.json";
import { NavLink } from "react-router-dom";
import { locationByLang, useLang } from "../../hooks/useLang";
import { ROUTES } from "../../Router";
import { ContextApp } from "../../context";

const About: React.FC = () => {
  const [ViewerUrl, changeViewerUrl] = useState<string | undefined>(undefined);

  const lang = useLang();
  const { T } = useLocale();
  const context = useContext(ContextApp);

  return (
    <>
      <div className="styles" id="naszestyle">
        <div className="styles-center">
          <div className="styles__header">{T(TO.styles.white.header)}</div>
          <div className="styles__subheader">
            {T(TO.styles.white.subheader)}
          </div>
          <div className="styles__list">
            <div className="styles__list-cell">
              <div className="styles__list-cell-wrapper">
                <div className="styles__list-cell-photo">
                  <img
                    src={amsterdam}
                    className="styles__list-cell-photo__image"
                    alt="amsterdam_style"
                  />
                </div>
                <div className="styles__list-cell-header">
                  {T(TO.styles.white.cell.header[1])}
                </div>
                <div className="styles__list-cell-description">
                  {T(TO.styles.white.cell.description[1])}
                </div>
                <div className="styles__list-buttons">
                  <button
                    className="button light"
                    onClick={() =>
                      openViewer(APP_CONFIG.Styles.ViewerAmsterdam)
                    }
                  >
                    {T(TO.styles.white.cell.button[1])}
                  </button>
                </div>
              </div>
            </div>
            <div className="styles__list-cell">
              <div className="styles__list-cell-wrapper">
                <div className="styles__list-cell-photo">
                  <img
                    src={paris}
                    className="styles__list-cell-photo__image"
                    alt="paris_style"
                  />
                </div>
                <div className="styles__list-cell-header">
                  {T(TO.styles.white.cell.header[2])}
                </div>
                <div className="styles__list-cell-description">
                  {T(TO.styles.white.cell.description[2])}
                </div>
                <div className="styles__list-buttons">
                  <button
                    className="button light"
                    onClick={() => openViewer(APP_CONFIG.Styles.ViewerParis)}
                  >
                    {T(TO.styles.white.cell.button[1])}{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="styles__list-cell">
              <div className="styles__list-cell-wrapper">
                <div className="styles__list-cell-photo">
                  <img
                    src={oslo}
                    className="styles__list-cell-photo__image"
                    alt="oslo_style"
                  />
                </div>
                <div className="styles__list-cell-header">
                  {T(TO.styles.white.cell.header[3])}
                </div>
                <div className="styles__list-cell-description">
                  {T(TO.styles.white.cell.description[3])}
                </div>
                <div className="styles__list-buttons">
                  <button
                    className="button light"
                    onClick={() => openViewer(APP_CONFIG.Styles.ViewerOslo)}
                  >
                    {T(TO.styles.white.cell.button[1])}{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="styles__list-cell">
              <div className="styles__list-cell-wrapper">
                <div className="styles__list-cell-photo">
                  <img
                    src={berlin}
                    className="styles__list-cell-photo__image"
                    alt="berlin_style"
                  />
                </div>
                <div className="styles__list-cell-header">
                  {T(TO.styles.white.cell.header[4])}
                </div>
                <div className="styles__list-cell-description">
                  {T(TO.styles.white.cell.description[4])}
                </div>
                <div className="styles__list-buttons">
                  <button
                    className="button light"
                    onClick={() => openViewer(APP_CONFIG.Styles.ViewerBerlin)}
                  >
                    {T(TO.styles.white.cell.button[1])}{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="styles__list-cell">
              <div className="styles__list-cell-wrapper">
                <div className="styles__list-cell-photo">
                  <img
                    src={london}
                    className="styles__list-cell-photo__image"
                    alt="london_style"
                  />
                </div>
                <div className="styles__list-cell-header">
                  {T(TO.styles.white.cell.header[5])}
                </div>
                <div className="styles__list-cell-description">
                  {T(TO.styles.white.cell.description[5])}
                </div>
                <div className="styles__list-buttons">
                  <button
                    className="button light"
                    onClick={() => openViewer(APP_CONFIG.Styles.ViewerLondon)}
                  >
                    {T(TO.styles.white.cell.button[1])}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="styles__list-buttons">
            <NavLink
              to={locationByLang(ROUTES.Contact, lang)}
              className="button light"
            >
              {T(TO.styles.white.cell.button[2])}{" "}
            </NavLink>
          </div>
        </div>
      </div>

      <div className="styles black">
        <div className="styles-bg-top">
          <svg
            className="contact__arrow"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            shapeRendering="geometricPrecision"
          >
            <polygon
              className="nav__logo-arrow-element"
              points="0 0,100 75,  100 100, 0 100"
            />
          </svg>
        </div>
        <div className="styles-bg">
          <div className="styles-center no-margin">
            <div className="styles__header big">
              {T(TO.styles.black.header)}
            </div>
            <div className="styles__offers">
              <div className="styles__offers-offer">
                <div className="styles__offers-offer__header">
                  {T(TO.styles.black.offer.header[1])}
                </div>
                <div className="styles__offers-offer__footer">
                  {T(TO.styles.black.offer.footer[1])}
                </div>
                <div className="styles__offers-offer__description">
                  {T(TO.styles.black.offer.description[1])}
                </div>
              </div>
              <div className="styles__offers-offer">
                <div className="styles__offers-offer__header">
                  {T(TO.styles.black.offer.header[2])}
                </div>
                <div className="styles__offers-offer__footer">
                  {T(TO.styles.black.offer.footer[2])}
                </div>
                <div className="styles__offers-offer__description">
                  {T(TO.styles.black.offer.description[2])}
                </div>
              </div>
              <div className="styles__offers-offer">
                <NavLink
                  to={locationByLang(ROUTES.Contact, lang)}
                  className="styles__offers-offer__header small"
                >
                  {T(TO.styles.black.offer.header[3])}
                </NavLink>

                <div className="styles__offers-offer__footer">
                  {T(TO.styles.black.offer.footer[3])}
                </div>
                <div className="styles__offers-offer__description">
                  {T(TO.styles.black.offer.description[3])}
                </div>
              </div>
            </div>
            {/* <div className="styles__list-buttons">
                            <NavLink to={locationByLang(ROUTES.Pricing, lang)} className="button white higher">
                                {T(TO.styles.black.offer.button)}
                            </NavLink>
                        </div> */}
          </div>
        </div>
        <div className="styles-bg-bottom">
          <svg
            className="contact__arrow"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            shapeRendering="geometricPrecision"
          >
            <polygon
              className="nav__logo-arrow-element"
              points="0 0, 100 0, 100 25, 0 100"
            />
          </svg>
        </div>
      </div>

      {ViewerUrl ? (
        <div className="styles-presentation">
          <div className="styles-presentation-bg">
            <iframe
              className="styles-presentation-iframe"
              src={ViewerUrl}
              frameBorder="none"
            />{" "}
            <div className="styles-presentation-button">
              <button className="button iframe" onClick={() => closeViewer()}>
                close
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );

  function openViewer(url: string | undefined) {
    changeViewerUrl(url);
    context.disableScroll.setIsDisabledScroll(true);
  }

  function closeViewer() {
    changeViewerUrl(undefined);
    context.disableScroll.setIsDisabledScroll(false);
  }
};

export default About;
