import { createContext } from 'react';
import { ContextUseDisableScroll, useDisableScroll } from '../hooks/useDisableScroll';

interface ContextAppProps {
    disableScroll: ContextUseDisableScroll;
}


export function useContextAppValues(): ContextAppProps {
    return {
        disableScroll: useDisableScroll()
    }
}

export const ContextApp = createContext<ContextAppProps>({} as ContextAppProps);