import React from "react";

export function Replace(
    source: string,
    searchFragment: string,
    reactFragment: React.ReactNode
) {
    return source.split(searchFragment).reduce(
        (output, current, index) => (
            <>
                {output}
                {index > 0 ? reactFragment : null}
                {current}
            </>
        ),
        <></>
    );
}
