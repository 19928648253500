import React from "react";
import icon2 from "../../assets/icon_2.svg";
import icon3 from "../../assets/icon_3.svg";
import icon4 from "../../assets/icon_4.svg";
import icon5 from "../../assets/icon_5.svg";
import icon6 from "../../assets/icon_6.svg";
import icon7 from "../../assets/icon_7.svg";
import icon8 from "../../assets/icon_8.svg";
import icon9 from "../../assets/icon_9.svg";
import icon10 from "../../assets/icon_10.svg";
import { useLocale } from "../../hooks/useLocale";

import TO from "../../Translate.json";
import { NavLink } from "react-router-dom";
import { locationByLang, useLang } from "../../hooks/useLang";
import { ROUTES } from "../../Router";

const About: React.FC = () => {
  const { T } = useLocale();
  const lang = useLang();
  return (
    <>
      <div className="about black" id="jaktodziala">
        <div className="about-bg-top">
          <svg
            className="contact__arrow"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            shapeRendering="geometricPrecision"
          >
            <polygon
              className="nav__logo-arrow-element"
              points="0 100, 0 75, 100 0, 100 100"
            />
          </svg>
        </div>
        <div className="about-bg">
          <div className="about-center">
            <div className="about__header">{T(TO.about.header)}</div>
            <div className="about__container">
              <div className="about__container-infos">
                <div className="about__container-infos__content">
                  <div className="about__container-infos__content-absolute">
                    <div className="about__container-infos__content-absolute-number">
                      1
                    </div>
                    <div className="about__container-infos__content-absolute-circle"></div>
                  </div>
                  <div className="about__container-infos__content-text">
                    {T(TO.about.container__black__text[1])}
                  </div>
                </div>
                <div className="about__container-infos__content">
                  <div className="about__container-infos__content-absolute">
                    <div className="about__container-infos__content-absolute-number">
                      2
                    </div>
                    <div className="about__container-infos__content-absolute-circle"></div>
                  </div>
                  <div className="about__container-infos__content-text">
                    {T(TO.about.container__black__text[2])}
                  </div>
                </div>
                <div className="about__container-infos__content">
                  <div className="about__container-infos__content-absolute">
                    <div className="about__container-infos__content-absolute-number">
                      3
                    </div>
                    <div className="about__container-infos__content-absolute-circle big">
                      done
                    </div>
                  </div>
                  <div className="about__container-infos__content-text">
                    {T(TO.about.container__black__text[3])}
                  </div>
                </div>
              </div>
            </div>
            <div className="about__container-button">
              <NavLink
                to={locationByLang(ROUTES.Contact, lang)}
                className="button lighter"
              >
                {T(TO.about.container__black__text.button)}
              </NavLink>
            </div>
          </div>
        </div>
        <div className="about-bg-bottom">
          <svg
            className="contact__arrow"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            shapeRendering="geometricPrecision"
          >
            <polygon
              className="nav__logo-arrow-element"
              points="0 25, 0 0, 100 0, 100 100"
            />
          </svg>
        </div>
      </div>
      <div className="about white">
        <div className="about-center">
          <div className="about__header">
            {T(TO.about.container__white.header)}
          </div>
          <div className="about__subheader">
            {T(TO.about.container__white.subheader)}
          </div>
          <div className="about__list">
            <div className="about__list-wrapper">
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon2}
                    className="about__list-cell-photo__image"
                    alt="subsription"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[1])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[1])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon3}
                    className="about__list-cell-photo__image"
                    alt="execution"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[2])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[2])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon4}
                    className="about__list-cell-photo__image"
                    alt="crossplatform"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[3])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[3])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon5}
                    className="about__list-cell-photo__image"
                    alt="analyst"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[4])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[4])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon6}
                    className="about__list-cell-photo__image"
                    alt="styles"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[5])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[5])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon7}
                    className="about__list-cell-photo__image"
                    alt="sellers"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[6])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[6])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon8}
                    className="about__list-cell-photo__image"
                    alt="installation"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[7])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[7])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon9}
                    className="about__list-cell-photo__image"
                    alt="send_throw"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[8])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[8])}
                </div>
              </div>
              <div className="about__list-cell">
                <div className="about__list-cell-photo">
                  <img
                    src={icon10}
                    className="about__list-cell-photo__image"
                    alt="packages"
                  />
                </div>
                <div className="about__list-cell-header">
                  {T(TO.about.container__white.cell.header[9])}
                </div>
                <div className="about__list-cell-description">
                  {T(TO.about.container__white.cell.description[9])}
                </div>
              </div>
            </div>
          </div>
          <div className="about__container-button">
            <NavLink
              to={locationByLang(ROUTES.Contact, lang)}
              className="button light"
            >
              {T(TO.about.container__white.cell.button)}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="about white">
        <div className="about-center">
          <div className="about__header">{T(TO.about.header360)}</div>
          <div className="about__subheader">{T(TO.about.subheader360)}</div>
          <iframe src="https://360.3destate.pl/website/oslo?isTour=true" className="about__360container"></iframe>
        </div>
      </div>
    </>
  );
};

export default About;
