import { EndpointDefinition } from "@nexcodepl/endpoint-types";

export interface EndpointPostContactParams {
    email: string;
    name: string;
    phone: string;
    message: string;
}

export const EndpointPostContact: EndpointDefinition<undefined, EndpointPostContactParams, undefined, false> = {
    url: APP_CONFIG.ContactFormUrl,
    method: "POST",
    secure: false,
};
