import { useEffect, useState } from "react";

export function useStateLocalStorage<StateType>(
    initState: StateType,
    localStorageKey: string
): [StateType, React.Dispatch<React.SetStateAction<StateType>>] {
    const [state, setState] = useState(initState);

    useEffect(() => {
        const lsValue = window.localStorage.getItem(localStorageKey);
        try {
            if (lsValue === undefined || lsValue === null) return;
            const parsedValue: { state: StateType } = JSON.parse(lsValue);
            setState(parsedValue.state);
        } catch (e) {}
    }, [localStorageKey]);

    useEffect(() => {
        try {
            const valueToSave = JSON.stringify({ state: state });
            window.localStorage.setItem(localStorageKey, valueToSave);
        } catch (e) {}
    }, [state, localStorageKey]);

    return [state, setState];
}
