import React, { useEffect, useState } from "react";
import { useLocale } from "../hooks/useLocale";
import { useStateLocalStorage } from "../hooks/useStateWithLocalStorage";

import TO from "../Translate.json";

import "./CookieInfo.scss";

const CookieInfo: React.FC = () => {
  const [isClosed, setIsClode] = useStateLocalStorage(
    false,
    "cookieInfoAccepted"
  );

  const { T } = useLocale();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const t = window.setTimeout(() => setIsReady(true), 200);

    return () => window.clearTimeout(t);
  });

  if (!isReady || !!isClosed) return null;

  return (
    <div className="cookie-info">
      <div className="cookie-info__text">{T(TO.cookies)}</div>
      <div className="cookie-info__icon" onClick={() => setIsClode(true)}>
        close
      </div>
    </div>
  );
};

export default CookieInfo;
