import { useState } from "react";

export interface ContextUseDisableScroll {
    isDisabledScroll: boolean;
    setIsDisabledScroll: (isDisabledScroll: boolean) => void;
}

export function useDisableScroll(): ContextUseDisableScroll {
    const [isDisabledScroll, setIsDisabledScroll] = useState<boolean>(false);

    return { isDisabledScroll, setIsDisabledScroll }
}