import { useHistory } from "react-router-dom";

export function goToStartSection(id: string, mode?: "smooth" | "auto") {
    const section = document.getElementById(id);
    if (!section) return;
    setTimeout(() => {
        window.scrollTo({
            top: section.offsetTop - 120,
            behavior: mode || "smooth",
        });
    }, 100);
}
