import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ReactHelmet from "react-helmet";

import Pricing from "./areas/Pricing";
import MainPage from "./areas/MainPage/MainPage";
import PrivacyPolicy from "./areas/MainPage/PrivacyPolicy";
import Regulations from "./areas/MainPage/Regulations";
import Makieta from "./areas/MainPage/Makieta";
import { LocaleObject, useLocale } from "./hooks/useLocale";
import { useLang, locationStripLang } from "./hooks/useLang";
import { idText } from "typescript";

export const ROUTES = {
  Start: { pl: "/Start", en: "/Home", de: "/Start" },
  HowItWorks: {
    pl: "/Start#JakToDziala",
    en: "/Home#HowItWorks",
    de: "/Start#WieFunktioniertEs",
  },
  Styles: {
    pl: "/Start#NaszeStyle",
    en: "/Home#OurStyles",
    de: "/Start#UnsereStile",
  },
  Contact: { pl: "/Start#Kontakt", en: "/Home#Contact", de: "/Start#Kontakt" },
  //Pricing: { pl: "/Cennik", en: "/Pricing" },
  PrivacyPolicy: {
    pl: "/PolitykaPrywatnosci",
    en: "/PrivacyPolicy",
    de: "/Start#DatenschutzbestimmungenTe",
  },
  Terms: { pl: "/Regulamin", en: "/Terms", de: "/Start#Bedingungen" },
  SmartModel: { pl: "/SmartMakieta", en: "/SmartModel", de: "/SmartModel" },
};

const Router: React.FC = () => {
  const lang = useLang();
  const { TS } = useLocale();

  const savedLang = getSavedLang();
  const browserLang = getBrowserLang();

  if (window.navigator.userAgent !== "ReactSnap") {
    if (savedLang === null) {
      if (lang !== browserLang) {
        return (
          <Redirect
            to={`/${browserLang}${locationStripLang(
              window.location.pathname + window.location.hash
            )}`}
          ></Redirect>
        );
      }
    } else if (lang !== savedLang) {
      return (
        <Redirect
          to={`/${savedLang}${locationStripLang(
            window.location.pathname + window.location.hash
          )}`}
        ></Redirect>
      );
    }
  }

  return (
    <>
      <ReactHelmet
        title={TS({
          pl: "Wirtualny Spacer • 3D Estate - Spacery 3D dla deweloperów",
          en: "Virtual Tour • 3D Estate - 3D tours for apartment developers ",
        })}
      >
        <html lang={lang} />
        <meta
          name="description"
          content={TS({
            pl: "Wirtualne spacery i rzuty 3D dla każdego mieszkania inwestycji. Aplikacje, które pozwolą zobaczyć mieszkania przed wybudowaniem! Interaktywne makiety nowej generacji.",
            en: "Virtual tours and 3D plans for every apartment. See move-in ready apartments before they’re finished. Interactive building models of whole investments.",
          })}
        />
      </ReactHelmet>
      <Switch>
        {RouteLocaleRender(ROUTES.HowItWorks, <MainPage />)}
        {RouteLocaleRender(ROUTES.Styles, <MainPage />)}
        {RouteLocaleRender(ROUTES.Contact, <MainPage />)}
        {RouteLocaleRender(ROUTES.Start, <MainPage />)}
        {/* {RouteLocaleRender(ROUTES.Pricing, <Pricing />)} */}
        {RouteLocaleRender(ROUTES.SmartModel, <Makieta />)}
        {RouteLocaleRender(ROUTES.PrivacyPolicy, <PrivacyPolicy />)}
        {RouteLocaleRender(ROUTES.Terms, <Regulations />)}
        <Redirect from="/en" to={ROUTES.Start.en} />
        <Redirect from="*" to={ROUTES.Start.pl} />
      </Switch>
    </>
  );
};

export default Router;

function RouteLocaleRender(path: LocaleObject, children: React.ReactNode) {
  return [
    <Route path={`/pl${path.pl}`} key={`/pl${path.pl}`}>
      {children}
    </Route>,
    <Route path={`/en${path.en}`} key={`/en${path.en}`}>
      {children}
    </Route>,
    <Route path={`/de${path.de}`} key={`/de${path.de}`}>
      {children}
    </Route>,
    <Redirect
      from={`/pl${path.en}`}
      to={`/pl${path.pl}`}
      key={`redirect/pl${path.en}`}
    />,
    <Redirect
      from={`/pl${path.de}`}
      to={`/pl${path.pl}`}
      key={`redirect/pl${path.de}`}
    />,
    <Redirect
      from={`/en${path.pl}`}
      to={`/en${path.en}`}
      key={`redirect/en${path.pl}`}
    />,
    <Redirect
      from={`/en${path.de}`}
      to={`/en${path.en}`}
      key={`redirect/en${path.de}`}
    />,
    <Redirect
      from={`/de${path.pl}`}
      to={`/de${path.de}`}
      key={`redirect/de${path.pl}`}
    />,
    <Redirect
      from={`/de${path.en}`}
      to={`/de${path.de}`}
      key={`redirect/de${path.en}`}
    />,
    <Redirect
      from={path.pl}
      to={`/pl${path.pl}`}
      key={`redirect/${path.pl}`}
    />,
    <Redirect
      from={path.en}
      to={`/en${path.en}`}
      key={`redirect/${path.en}`}
    />,
    <Redirect
      from={path.de}
      to={`/de${path.de}`}
      key={`redirect/${path.de}`}
    />,
  ];
}

function getBrowserLang() {
  const lang = window.navigator.language;

  if (lang.indexOf("pl") !== -1) return "pl";
  if (lang.indexOf("de") !== -1) return "de";

  return "en";
}

function getSavedLang() {
  const lang = window.localStorage.getItem("saved-lang");
  if (!lang) return null;
  if (!["pl", "en", "de"].includes(lang)) return null;
  return lang;
}
