import React, { useEffect, useRef, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import TO from "../../Translate.json";
import ReactHelmet from "react-helmet";
import { useDatasource } from "@nexcodepl/endpoint-client";
import { EndpointPostContact } from "../../endpoints/PostContact";
import Checkbox from "../../forms/Checkbox";
import { Link } from "react-router-dom";
import { locationByLang, useLang } from "../../hooks/useLang";
import { ROUTES } from "../../Router";

type SendStateState = "idle" | "pending" | "completed" | "error";

interface MakietaForm {
    email: string;
    agreement1: boolean;
    agreement2: boolean;
}

const makietaFormDefault: MakietaForm = {
    email: "",
    agreement1: false,
    agreement2: false,
};

const Makieta: React.FC = () => {
    const { T, TS } = useLocale();

    const lang = useLang();

    const dsSend = useDatasource(EndpointPostContact);
    const [fieldError, setFieldError] = useState<string | null>(null);
    const [agreementError, setAgreementError] = useState<boolean>(false);

    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(6);

    const interval = useRef<number | undefined>();

    useEffect(() => {
        if (interval.current) {
            window.clearInterval(interval.current);
            interval.current = undefined;
        }
        interval.current = window.setInterval(
            () =>
                setIndex((p) => {
                    if (p + 1 > 9) {
                        return 1;
                    } else {
                        return p + 1;
                    }
                }),
            3000
        );

        return () => {
            if (interval.current) {
                window.clearInterval(interval.current);
                interval.current = undefined;
            }
        };
    }, []);

    const [makietaForm, makietaFormChange] = useState<MakietaForm>(makietaFormDefault);

    useEffect(() => {
        if (makietaForm.agreement1) {
            setAgreementError(false);
        }
    }, [makietaForm.agreement1]);

    const sendState = dsSend.state.state;

    async function send() {
        if (!validate()) return;

        dsSend.load({
            data: {
                email: makietaForm.email,
                name: "-",
                message: `Zainteresowanie Smart Makietą. 

${
    makietaForm.agreement1 ? "Wyrażam zgodę na: " : "Nie wyrażam zgody na: "
} PRZETWARZANIE MOICH DANYCH OSOBOWYCH W CELACH MARKETINGU PRODUKTÓW I USŁUG 3D ESTATE.             
${
    makietaForm.agreement2 ? "Wyrażam zgodę na: " : "Nie wyrażam zgody na: "
} OTRZYMYWANIE I INFORMACJI HANDLOWEJ NA WSKAZANY PRZEZE MNIE ADRES E-MAIL LUB PODANY NUMER TELEFONU.             
                
                `,
                phone: "-",
            },
        });
    }

    useEffect(() => {
        validate(true);
    }, [makietaForm.email]);

    function validate(reduce?: boolean) {
        let error: string | null = null;
        if (!ValidateEmail(makietaForm.email)) {
            error = "InvalidEmail";
        }
        if (!reduce || error === null) {
            setFieldError(error);
        }

        if (!reduce) {
            if (!makietaForm.agreement1) {
                setAgreementError(true);
                return false;
            }
        }
        return error === null;
    }

    function setField(fieldName: keyof MakietaForm, value: string) {
        makietaFormChange((p) => ({ ...p, [fieldName]: value }));
    }

    function fieldProps(fieldName: keyof MakietaForm) {
        return {
            value: makietaForm[fieldName] as string,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setField(fieldName, e.target.value),
        };
    }

    function closeOnSuccess() {
        dsSend.reset();
        makietaFormChange(makietaFormDefault);
    }

    function closeOnError() {
        dsSend.reset();
    }

    return (
        <div className="makieta">
            <ReactHelmet
                title={TS({
                    pl: "Smart Makieta • Interaktywna makieta nowej generacji",
                    en: "Smart buildings model • Interactive Model of New Generation",
                })}
            >
                <meta
                    name="description"
                    content={TS({
                        pl:
                            "Wirtualna makieta nowej generacji online i w biurze sprzedaży dostępna na każdym urządzeniu.",
                        en: "Virtual model of new generation online and in showrooms, on every device.",
                    })}
                />
            </ReactHelmet>
            <div className="makieta__video">
                <video className="makieta__video-video" autoPlay={true} muted={true} loop={true}>
                    <source src="/start-video.mp4" type="video/mp4" />
                </video>
            </div>
            <div className={`makieta__middle  item-${index}`}>
                <div className="makieta__middle-item">
                    {TS({
                        pl: "Interaktywna makieta online dostępna na każdym urządzeniu",
                        en: "Interactive model online for every device",
                    })}
                </div>
                <div className="makieta__middle-item">
                    {TS({ pl: "Aplikacja 4K dla biur sprzedaży", en: "Application for showrooms in 4K" })}
                </div>
                <div className="makieta__middle-item">
                    {TS({
                        pl: "Wirtualny spacer i obracany model dla każdego mieszkania",
                        en: "Virtual Tours and 3D models of every apartment",
                    })}
                </div>
                <div className="makieta__middle-item">
                    {TS({
                        pl: "Nowoczesna Karta dla każdego Mieszkania",
                        en: "Balcony views from every apartment",
                    })}
                </div>
                <div className="makieta__middle-item">
                    {TS({ pl: "Intuicyjna wyszukiwarka mieszkań", en: "Intuitive apartment searching" })}
                </div>
                <div className="makieta__middle-item">
                    {TS({
                        pl: "Moduł lokalizacji spersonalizowany dla każdego klienta",
                        en: "Localization module personalized for every customer",
                    })}
                </div>
                <div className="makieta__middle-item">
                    {TS({ pl: "Galeria zdjęć i grafik inwestycji", en: "Photos and Videos gallery" })}
                </div>
                <div className="makieta__middle-item">
                    {TS({
                        pl: "Gotowa grafika do promocji w internecie i outdoorze",
                        en: "ready for online and outdoor promotion",
                    })}
                </div>
                <div className="makieta__middle-item">{TS({ pl: "Integracja z CRM", en: "CRM integration" })}</div>
            </div>
            <div className="makieta__bottom">
                <div className="makieta__bottom-top">
                    <div className="makieta__bottom-top__header">{T(TO.makieta.bottom.header)}</div>
                    <div className="makieta__bottom-top__subheader">{T(TO.makieta.bottom.subheader)}</div>
                </div>
                <div className="makieta__bottom-bottom">
                    <div className="makieta__bottom-bottom__text">{T(TO.makieta.bottom.text)}</div>
                    <div className={`makieta__bottom-bottom__container`}>
                        <div className={`makieta__bottom-form ${sendState !== "idle" ? "disabled" : ""}`}>
                            <input
                                className={`makieta__bottom-bottom__container-input ${
                                    fieldError !== null ? "is-error" : ""
                                }`}
                                placeholder={TS(TO.makieta.bottom.placeholder)}
                                {...fieldProps("email")}
                                onFocus={() => setIsFocus(true)}
                                onBlur={() => setIsFocus(false)}
                            />

                            <div className="makieta__bottom-bottom__container-buttons">
                                <button
                                    className="makieta__bottom-bottom__container-buttons__button"
                                    onClick={() => send()}
                                >
                                    {T(TO.makieta.bottom.button)}
                                </button>
                            </div>
                        </div>
                        <div className={`makieta__bottom-checkboxes ${sendState !== "idle" ? "disabled" : ""}`}>
                            <Checkbox
                                className={agreementError ? "is-error" : ""}
                                value={makietaForm.agreement1}
                                changeValue={(v) => {
                                    makietaFormChange((p) => ({
                                        ...p,
                                        agreement1: v,
                                    }));
                                }}
                                label={TS(TO.contact.agreements[1])}
                            />

                            <Checkbox
                                value={makietaForm.agreement2}
                                changeValue={(v) => {
                                    makietaFormChange((p) => ({
                                        ...p,
                                        agreement2: v,
                                    }));
                                }}
                                label={TS(TO.contact.agreements[2])}
                            />
                        </div>

                        {sendState === "pending" ? (
                            <div className="makieta__bottom-bottom__container-overlay">
                                <div className="makieta__bottom-bottom__container-overlay__content">
                                    <div className="makieta__bottom-bottom__container-overlay__content-header">
                                        <div className="makieta__bottom-bottom__container-overlay__content-header__icon">
                                            hourglass_bottom
                                        </div>
                                        {T(TO.makieta.overlay.header[1])}
                                    </div>
                                    <div className="makieta__bottom-bottom__container-overlay__content-buttons"></div>
                                </div>
                            </div>
                        ) : null}
                        {sendState === "completed" ? (
                            <div className="makieta__bottom-bottom__container-overlay">
                                <div className="makieta__bottom-bottom__container-overlay__content">
                                    <div className="makieta__bottom-bottom__container-overlay__content-header">
                                        <div className="makieta__bottom-bottom__container-overlay__content-header__icon">
                                            done
                                        </div>
                                        {T(TO.makieta.overlay.header[2])}
                                    </div>
                                    <div className="makieta__bottom-bottom__container-overlay__content-buttons">
                                        <button
                                            className="makieta__bottom-bottom__container-buttons__button"
                                            onClick={() => closeOnSuccess()}
                                        >
                                            {T(TO.makieta.overlay.button[1])}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {sendState === "error" ? (
                            <div className="makieta__bottom-bottom__container-overlay">
                                <div className="makieta__bottom-bottom__container-overlay__content">
                                    <div className="makieta__bottom-bottom__container-overlay__content-header">
                                        {T(TO.makieta.overlay.header[3])}
                                    </div>
                                    <div className="makieta__bottom-bottom__container-overlay__content-buttons">
                                        <button
                                            className="makieta__bottom-bottom__container-buttons__button"
                                            onClick={() => closeOnError()}
                                        >
                                            {T(TO.makieta.overlay.button[1])}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className={`makieta__policy ${isFocus ? "" : "hide"}`}>
                        <div className="makieta__policy-text">
                            {T(TO.contact.smalltext)}
                            <br />
                            <br />
                            {T(TO.contact.footer.text)}
                        </div>
                        <div className="makieta__policy-footer">
                            2020 © 3D Estate
                            <br />
                            <Link
                                to={locationByLang(ROUTES.PrivacyPolicy, lang)}
                                className="makieta__policy-footer-link"
                            >
                                {T(TO.contact.footer.privacy)}
                            </Link>
                            &nbsp;
                            <Link to={locationByLang(ROUTES.Terms, lang)} className="makieta__policy-footer-link">
                                {T(TO.contact.footer.terms)}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Makieta;

export function ValidateEmail(email: string): boolean {
    if (typeof email !== "string") return false;
    return new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email);
}
