import React from "react";
import logo2 from "../../assets/logo2.png";
import ronson from "../../assets/ronson.png";
import TO from "../../Translate.json";
import { useLocale } from "../../hooks/useLocale";

const WhoTrusted: React.FC = () => {
    const { T } = useLocale();

    return (
        <div className="whotrusted" id="zaufalinam">
            <div className="whotrusted-center">
                <div className="whotrusted__header">{T(TO.whotrusted.header)}</div>
                <div className="whotrusted__opinions">
                    <div className="whotrusted__opinions-opinion">
                        <div className="whotrusted__opinions-opinion-wrapper">
                            <div className="whotrusted__opinions-opinion-absolute">format_quote</div>

                            <div className="whotrusted__opinions-opinion-head">
                                <div className="whotrusted__opinions-opinion-head__photo">
                                    <img
                                        src={logo2}
                                        className="whotrusted__opinions-opinion-head__photo-image"
                                        alt="bpi_logo"
                                    />
                                </div>
                                <div className="whotrusted__opinions-opinion-head-header">
                                    <div className="whotrusted__opinions-opinion-head-header__title">
                                        {T(TO.whotrusted.opinion.header[1])}
                                    </div>
                                    <div className="whotrusted__opinions-opinion-head-header__company">
                                        {T(TO.whotrusted.opinion.company[1])}
                                    </div>
                                </div>
                            </div>
                            <div className="whotrusted__opinions-opinion-description">
                                {T(TO.whotrusted.opinion.description[1])}
                            </div>
                        </div>
                    </div>
                    <div className="whotrusted__opinions-opinion">
                        <div className="whotrusted__opinions-opinion-wrapper">
                            <div className="whotrusted__opinions-opinion-absolute">format_quote</div>
                            <div className="whotrusted__opinions-opinion-head">
                                <div className="whotrusted__opinions-opinion-head__photo">
                                    <img
                                        src={ronson}
                                        className="whotrusted__opinions-opinion-head__photo-image"
                                        alt="bpi_logo"
                                    />
                                </div>
                                <div className="whotrusted__opinions-opinion-head-header">
                                    <div className="whotrusted__opinions-opinion-head-header__title">
                                        {T(TO.whotrusted.opinion.header[2])}
                                    </div>
                                    <div className="whotrusted__opinions-opinion-head-header__company">
                                        {T(TO.whotrusted.opinion.company[2])}
                                    </div>
                                </div>
                            </div>
                            <div className="whotrusted__opinions-opinion-description">
                                {T(TO.whotrusted.opinion.description[2])}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whotrusted__companies">
                    <div className="whotrusted__companies-row">
                        {APP_CONFIG.WhoTrusted.Logos.map((e, index) => (
                            <div className="whotrusted__companies-row__cell" key={index}>
                                <div className="whotrusted__companies-row__cell-photo">
                                    <img
                                        src={e.src}
                                        alt={e.alt}
                                        className="whotrusted__companies-row__cell-photo-img"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhoTrusted;
