import React, { useEffect, useState } from "react";
import TO from "../../Translate.json";
import { useLocale } from "../../hooks/useLocale";
import Checkbox from "../../forms/Checkbox";
import { Link, NavLink } from "react-router-dom";
import { locationByLang, useLang } from "../../hooks/useLang";
import { ROUTES } from "../../Router";
import { useDatasource } from "@nexcodepl/endpoint-client";
import { EndpointPostContact } from "../../endpoints/PostContact";

type SendStateState = "idle" | "pending" | "completed" | "error";

interface Props {
  className?: string;
}

interface ContactForm {
  name: string;
  email: string;
  phone: string;
  message: string;
  agreement1: boolean;
  agreement2: boolean;
}

const contactFormDefault: ContactForm = {
  name: "",
  email: "",
  phone: "",
  message: "",
  agreement1: false,
  agreement2: false,
};

interface FormError {
  fieldName: keyof ContactForm;
  error: string;
}

const Contact: React.FC<Props> = ({ className }) => {
  const { T, TS } = useLocale();

  const dsSend = useDatasource(EndpointPostContact);

  const sendState = dsSend.state.state;

  const lang = useLang();

  const [contactForm, contactFormChange] = useState<ContactForm>(
    contactFormDefault
  );
  const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);

  useEffect(() => {
    validateFieldOnly("agreement1", true);
  }, [contactForm.agreement1]);

  async function send() {
    if (!validate()) {
      return;
    }

    dsSend.load({
      data: {
        email: contactForm.email,
        name: contactForm.name,
        phone: contactForm.phone,
        message:
          contactForm.message +
          `

${
  contactForm.agreement1 ? "Wyrażam zgodę na: " : "Nie wyrażam zgody na: "
} PRZETWARZANIE MOICH DANYCH OSOBOWYCH W CELACH MARKETINGU PRODUKTÓW I USŁUG 3D ESTATE.             
${
  contactForm.agreement2 ? "Wyrażam zgodę na: " : "Nie wyrażam zgody na: "
} OTRZYMYWANIE I INFORMACJI HANDLOWEJ NA WSKAZANY PRZEZE MNIE ADRES E-MAIL LUB PODANY NUMER TELEFONU.             
                `,
      },
    });
  }

  function setField(fieldName: keyof ContactForm, value: string) {
    contactFormChange((p) => ({ ...p, [fieldName]: value }));
  }

  function fieldProps(fieldName: keyof ContactForm) {
    return {
      value: contactForm[fieldName] as string,
      onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setField(fieldName, e.target.value);
        validateFieldOnly(fieldName, true);
      },
      onBlur: () => {
        validateFieldOnly(fieldName);
      },
    };
  }

  function closeOnSuccess() {
    dsSend.reset();
    contactFormChange(contactFormDefault);
  }

  function closeOnError() {
    dsSend.reset();
  }

  function validate() {
    const errors: FormError[] = [
      ...validateField("email"),
      ...validateField("message"),
      ...validateField("name"),
      ...validateField("phone"),
      ...validateField("agreement1"),
    ];

    setContactFormErrors(errors);

    return errors.length === 0;
  }

  function validateFieldOnly(fieldName: keyof ContactForm, reduce?: boolean) {
    const fieldErrors = validateField(fieldName);

    setContactFormErrors((p) => {
      if (reduce) {
        return [...p].filter(
          (e) =>
            e.fieldName !== fieldName ||
            fieldErrors.findIndex(
              (er) => er.fieldName === fieldName && er.error === e.error
            ) !== -1
        );
      } else {
        const t = [...p].filter((e) => e.fieldName !== fieldName);
        t.push(...fieldErrors);
        return t;
      }
    });
  }

  function validateField(fieldName: keyof ContactForm) {
    const errors: FormError[] = [];
    switch (fieldName) {
      case "email":
        if (!ValidateEmail(contactForm.email)) {
          errors.push({ fieldName: "email", error: "InvalidEmail" });
        }
        break;

      case "name":
      case "message":
        if (!ValidateNotEmpty(contactForm[fieldName])) {
          errors.push({ fieldName: fieldName, error: "NotEmptyRequired" });
        }
        break;

      case "phone":
        if (!ValidateTelephone(contactForm[fieldName])) {
          errors.push({ fieldName: fieldName, error: "InvalidPhone" });
        }
        break;

      case "agreement1":
      case "agreement2":
        if (!contactForm[fieldName]) {
          errors.push({ fieldName: fieldName, error: "Required" });
        }
        break;
    }

    return errors;
  }

  function errorToString(error: string) {
    switch (error) {
      case "InvalidEmail":
        return TS({ pl: "Niepoprawny adres email", en: "Invalid email" });

      case "NotEmptyRequired":
        return TS({
          pl: "Pole nie może być puste",
          en: "Field cannot be empty",
        });

      case "Required":
        return TS({ pl: "Zgoda wymagana", en: "Agreement is required" });

      case "InvalidPhone":
        return TS({
          pl: "Niepoprawny numer telefonu",
          en: "Invalid phone number",
        });

      default:
        return error;
    }
  }

  function fieldErrors(fieldName: keyof ContactForm) {
    return contactFormErrors
      .filter((e) => e.fieldName === fieldName)
      .map((e, index) => (
        <div className="contact__field-error" key={`${fieldName}-${e.error}`}>
          {errorToString(e.error)}
        </div>
      ));
  }

  return (
    <div className={`contact ${className || ""}`} id="kontakt">
      <div className="contact-bg-top">
        <svg
          className="contact__arrow"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          shapeRendering="geometricPrecision"
        >
          <polygon
            className="nav__logo-arrow-element"
            points="0 0,100 75, 100 100, 0 100"
          />
        </svg>
      </div>
      <div className="contact-bg">
        <div className="contact-center">
          <div className="contact__box">
            <div className="contact__box-column">
              <div className="contact__box-column__header">
                {T(TO.contact.header[1])}
              </div>
              <div className="contact__box-column-wrapper">
                <div className="contact__box-column__subheader">
                  {T(TO.contact.subheader[1])}
                </div>
                <input
                  className="contact__box-column__input"
                  placeholder={TS(TO.contact.placeholder[1])}
                  {...fieldProps("name")}
                />
                {fieldErrors("name")}
                <input
                  className="contact__box-column__input"
                  placeholder={TS(TO.contact.placeholder[2])}
                  {...fieldProps("email")}
                />
                {fieldErrors("email")}
                <input
                  className="contact__box-column__input"
                  placeholder={TS(TO.contact.placeholder[3])}
                  {...fieldProps("phone")}
                />
                {fieldErrors("phone")}
                <div className="contact__box-column__textare">
                  <textarea
                    className="contact__box-column__textarea"
                    placeholder={TS(TO.contact.placeholder[4])}
                    {...fieldProps("message")}
                  />
                </div>
                {fieldErrors("message")}

                <div className="contact__checkbox">
                  <Checkbox
                    value={contactForm.agreement1}
                    changeValue={(v) => {
                      contactFormChange((p) => ({
                        ...p,
                        agreement1: v,
                      }));
                    }}
                    label={TS(TO.contact.agreements[1])}
                  />
                </div>
                {fieldErrors("agreement1")}

                <div className="contact__checkbox">
                  <Checkbox
                    value={contactForm.agreement2}
                    changeValue={(v) => {
                      contactFormChange((p) => ({
                        ...p,
                        agreement2: v,
                      }));
                    }}
                    label={TS(TO.contact.agreements[2])}
                  />
                </div>
                {fieldErrors("agreement2")}

                <div className="contact__box-column__buttons">
                  <button className="button" onClick={() => send()}>
                    {T(TO.contact.button[1])}
                  </button>
                </div>

                {sendState === "pending" ? (
                  <div className="contact__box-column-wrapper__overlay">
                    <div className="contact__box-column-wrapper__overlay-content">
                      <div className="contact__box-column-wrapper__overlay-header">
                        <div className="contact__box-column-wrapper__overlay-icon">
                          hourglass_bottom
                        </div>
                        {T(TO.contact.form.loading)}
                      </div>
                    </div>
                  </div>
                ) : null}
                {sendState === "completed" ? (
                  <div className="contact__box-column-wrapper__overlay">
                    <div className="contact__box-column-wrapper__overlay-content">
                      <div className="contact__box-column-wrapper__overlay-header">
                        <div className="contact__box-column-wrapper__overlay-icon">
                          done
                        </div>
                        {T(TO.contact.form.sent)}
                      </div>
                      <div className="contact__box-column-wrapper__overlay-buttons">
                        <button
                          className="button retry"
                          onClick={() => closeOnSuccess()}
                        >
                          ok
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sendState === "error" ? (
                  <div className="contact__box-column-wrapper__overlay">
                    <div className="contact__box-column-wrapper__overlay-content">
                      <div className="contact__box-column-wrapper__overlay-header">
                        <div className="contact__box-column-wrapper__overlay-icon"></div>
                        {T(TO.contact.form.error)}
                      </div>
                      <div className="contact__box-column-wrapper__overlay-buttons">
                        <button
                          className="button retry"
                          onClick={() => closeOnError()}
                        >
                          <div className="contact__box-column-wrapper__overlay-icon">
                            replay
                          </div>
                          {T(TO.contact.button[2])}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="contact__box-column__smalltext">
                  {T(TO.contact.smalltext)}
                </div>
              </div>
            </div>

            <div className="contact__box-column small">
              <div className="contact__box-column__header">
                {T(TO.contact.header[2])}
              </div>
              <div className="contact__box-column-info">
                <div className="contact__box-column-info-row">
                  <div className="contact__box-column-info-row__icon">
                    phone_enabled
                  </div>
                  <div className="contact__box-column-info-row__content">
                    +48 695 981 060
                  </div>
                </div>
                <div className="contact__box-column-info-row">
                  <div className="contact__box-column-info-row__icon">
                    mail_outline
                  </div>
                  <div className="contact__box-column-info-row__content">
                    kontakt@3destate.pl
                  </div>
                </div>
                <div className="contact__box-column-info-row column">
                  <div className="contact__box-column-info-row__city">
                    Mikołów
                  </div>
                  <div className="contact__box-column-info-row__address">
                    Wyzwolenia 27, 43-190
                  </div>
                </div>
                <div className="contact__box-column-info-row column">
                  <div className="contact__box-column-info-row__city">
                    {T(TO.contact.warsaw)}
                  </div>
                  <div className="contact__box-column-info-row__address">
                    Sarmacka 7J, 02-972
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact__footer">
            <div className="contact__footer-text">
              {T(TO.contact.footer.text)}
            </div>
            <div className="contact__footer-policy">
              2020 © 3D Estate{" "}
              <Link
                to={locationByLang(ROUTES.PrivacyPolicy, lang)}
                className="contact__footer-policy-link"
              >
                {T(TO.contact.footer.privacy)}
              </Link>
              <Link
                to={locationByLang(ROUTES.Terms, lang)}
                className="contact__footer-policy-link"
              >
                {T(TO.contact.footer.terms)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

export function ValidateEmail(email: string): boolean {
  if (typeof email !== "string") return false;
  return new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email);
}

export function ValidateNotEmpty(value: string): boolean {
  if (typeof value !== "string") return false;
  return value.trim().length > 0;
}

export function FormatTelephone(telephone: string): string {
  return telephone
    .replace(" ", "")
    .replace("-", "")
    .replace("(", "")
    .replace(")", "");
}

export function ValidateTelephone(telephone: string): boolean {
  if (typeof telephone !== "string") return false;
  telephone = telephone.trim();
  return new RegExp(/^(\+{1}[0-9]{1,3}|)[0-9]{9}$/).test(
    FormatTelephone(telephone)
  );
}
