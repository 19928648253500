import React from "react";
import { BrowserRouter } from "react-router-dom";
import Nav from "./areas/Nav";
import Modal from "./areas/Modal";
import Router from "./Router";
import ReactHelmet from "react-helmet";
import { useLang } from "./hooks/useLang";
import { ContextApp, useContextAppValues } from "./context";
import CookieInfo from "./areas/CookieInfo";
const App: React.FC = () => {
    const contextAppValues = useContextAppValues();

    return (
        <ContextApp.Provider value={contextAppValues}>
            <BrowserRouter>
                <div className={`router-home ${contextAppValues.disableScroll.isDisabledScroll ? 'is-disabled-scroll' : ''}`}>
                    <Nav />
                    <Router />
                    {/* <Modal /> */}
                </div>
                <CookieInfo />
            </BrowserRouter>
        </ContextApp.Provider>
    );
};

export default App;
