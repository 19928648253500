import React from "react";
import { hydrate, render } from "react-dom";

import "./scss/index.scss";

import App from "./App";
import { AppConfig } from "./AppConfig.types";

declare global {
    const APP_CONFIG: AppConfig;
}

const rootElement = document.getElementById("root");
if (!rootElement) {
    console.log("App crashed");
} else if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}
