import React from "react";
import "./Checkbox.scss";
interface Props {
    value: boolean;
    changeValue: (value: boolean) => void;
    label: string;
    className?: string;
}
const Checkbox: React.FC<Props> = ({ value, changeValue, label, className }) => {
    return (
        <div className={`form__checkbox ${className || ""}`}>
            <div className={`form__checkbox-square ${value ? "is-checked" : ""}`}>{value ? "check" : ""}</div>
            <input
                type="checkbox"
                checked={value}
                onChange={(e) => changeValue(e.target.checked)}
                className="form__checkbox-input"
            />

            <div className="form__checkbox-label" onClick={() => changeValue(!value)}>
                {label}
            </div>
        </div>
    );
};

export default Checkbox;
