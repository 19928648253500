import React, { useEffect } from "react";
import { useLocale } from "../../hooks/useLocale";
import TO from "../../Translate.json";
import ReactHelmet from "react-helmet";

const Regulations: React.FC = () => {
    const { T, TS } = useLocale();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="regulations">
            <ReactHelmet title={`3D Estate | ${TS({ pl: "Regulamin", en: "Terms" })}`}>
                <meta name="robots" content="noindex" />
                <meta
                    name="description"
                    content={TS({
                        pl: "Regulamin korzystania z aplikacji 3D Estate - spacery wirtualne, smart makieta.",
                        en: "Terms of service for 3D Estate apps - virtual tours, smart model.",
                    })}
                />
            </ReactHelmet>
            <div className="regulations__center">
                <div className="regulations__header">{T(TO.terms.header)}</div>
                <div className="regulations__text">{T(TO.terms.text1)}</div>
                <div className="regulations__text">{T(TO.terms.text2)}</div>
                <div className="regulations__text">{T(TO.terms.text3)}</div>
            </div>
        </div>
    );
};

export default Regulations;
