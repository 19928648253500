import { useLocation } from "react-router-dom";
import { LocaleObject } from "./useLocale";

export type Lang = "pl" | "en" | "de";

export function useLang(): Lang {
  const location = useLocation();

  const urlLang = location.pathname.substr(0, 3);

  const lang = locationToLang(urlLang.toLowerCase());

  return lang;
}

export function locationStripLang(location: string) {
  if (["/pl", "/en", "/de"].includes(location.substr(0, 3))) {
    return location.substr(3);
  }
  return location;
}

export function locationByLang(
  pathObject: LocaleObject,
  lang: Lang,
  prefix?: string
) {
  return `/${prefix || lang}${pathObject[lang]}`;
}

function locationToLang(urlLang: string) {
  switch (urlLang) {
    case "/en":
      return "en";
    case "/de":
      return "de";
    default:
      return "pl";
  }
}
